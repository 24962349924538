:global .container {
  margin: 0 auto;
  width: 80%;
  color: #333;
}
:global .variable-width .slick-slide p {
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}
:global .center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
:global .center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}
:global .content {
  padding: 20px;
  margin: auto;
  width: 90%;
}
:global .slick-slide .image {
  padding: 10px;
}
:global .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
}
:global .slick-slide img.slick-loading {
  border: 0;
}
:global .slick-dots {
  margin-left: 0;
}
:global .slick-thumb {
  bottom: -45px;
}
:global .slick-thumb li {
  width: 60px;
  height: 45px;
}
:global .slick-thumb li img {
  filter: grayscale(100%);
}
:global .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
@media (max-width: 768px) {
  .center {
    margin-left: -40px;
    margin-right: -40px;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
}
:global .slick-vertical .slick-slide {
  height: 180px;
}
/* .slick-arrow {
  background-color: grey;
  color: black;
}
.slick-arrow:hover {
  background-color: grey;
}
.slick-prev {
  z-index: 100;
  left: 20px !important;
}
.slick-next {
  z-index: 100;
  right: 20px !important;
} */
