$selected-color: rgba(255, 255, 255, 0.6);

:global .MuiToggleButton-root.Mui-selected {
  color: $selected-color;
  border-color: $selected-color !important;
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #464c51 inset;
  transition: background-color 5000s ease-in-out 0s;
}
